import { Card, Dropdown, Menu } from "antd";
import TwfButton from "component/button";
import { SelectTitle } from "component/header";
import { MarginBox } from "component/margin";
import { ConditionCodes } from "enums/enums";
import {
  ConfigArg,
  ConfigArgInput,
  ConfigurableOperationInput,
} from "generated/graphql";
import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import BuyXGetYFreeCondition from "./conditions/buy-x-get-y-free-condition";
import ContainsProductsCondition from "./conditions/contains-products-condition";
import CustomerClubCondition from "./conditions/customer-club-condition";
import CustomerGroupCondition from "./conditions/customer-group-condition";
import MininumOrderAmountCondition from "./conditions/min-order-amount-condition";
import MinimumOrderQuantityCondition from "./conditions/min-order-quantity";

export interface VariantOption {
  label: string;
  value: string;
}

export interface OperationProps {
  code: string;
  configArgs: ConfigArg[];
  onConfigArgChange: (configArg: ConfigArg, code: string) => void;
  onRemove: (code: string) => void;
}

export interface AvailableOperation {
  description: string;
  operation: ConfigurableOperationInput;
}

interface ConditionsProps {
  conditions: Array<ConfigurableOperationInput>;
  onConditionsChange: (conditions: Array<ConfigurableOperationInput>) => void;
}

const Conditions: FunctionComponent<ConditionsProps> = ({
  conditions,
  onConditionsChange,
}) => {
  const [selectedOperations, setSelectedOperations] = useState<
    ConfigurableOperationInput[]
  >([]);

  useEffect(() => {
    setSelectedOperations(conditions);
  }, [conditions]);

  const removeCard = (code: string) => {
    setSelectedOperations(
      selectedOperations.filter((operation) => operation.code !== code)
    );
    onConditionsChange(
      conditions.filter((condition) => condition.code !== code)
    );
  };

  const configArgChangeHandler = (configArgs: ConfigArgInput, code: string) => {
    onConditionsChange(
      conditions.map((condition) =>
        condition.code === code
          ? updateConditionArgs(condition, configArgs)
          : condition
      )
    );
  };

  const updateConditionArgs = (
    condition: ConfigurableOperationInput,
    configArg: ConfigArgInput
  ) => ({
    code: condition.code,
    arguments: condition.arguments.map((arg) =>
      arg.name === configArg.name ? configArg : arg
    ),
  });

  const addOperation = (operation: ConfigurableOperationInput) => {
    setSelectedOperations([...selectedOperations, operation]);
    onConditionsChange([...conditions, operation]);
  };

  const getConfigArgs = (operation: ConfigurableOperationInput) =>
    conditions.find((condition) => condition.code === operation.code)
      ?.arguments || [];

  const availableOperations: AvailableOperation[] = [
    {
      description: "If order total is greater than fix amount",
      operation: {
        code: ConditionCodes.MinimumOrderAmount,
        arguments: [{ name: "amount", value: "100" }],
      },
    },
    {
      description: "Buy at least minimum quantity of the specified products",
      operation: {
        code: ConditionCodes.ContainsProducts,
        arguments: [
          { name: "minimum", value: "10" },
          { name: "productVariantIds", value: "" },
        ],
      },
    },
    {
      description: "Buy at least minimum quantity of any products",
      operation: {
        code: ConditionCodes.ContainsMinAnyProducts,
        arguments: [{ name: "min", value: "0" }],
      },
    },
    // {
    //   description: "Customer is a member of the specified group",
    //   operation: {
    //     code: ConditionCodes.CustomerGroup,
    //     arguments: [{ name: "customerGroupId", value: "" }],
    //   },
    // },
    {
      description: "Customer is a member of the specified club",
      operation: {
        code: ConditionCodes.CustomerClubMember,
        arguments: [{ name: "clubId", value: "" }],
      },
    },
    // {
    //   description:
    //     "Buy certain amount of one product, get certain amount of other products free",
    //   operation: {
    //     code: ConditionCodes.BuyXGetYFree,
    //     arguments: [
    //       { name: "amountX", value: "1" },
    //       { name: "variantIdsX", value: "" },
    //       { name: "amountY", value: "2" },
    //       { name: "variantIdsY", value: "" },
    //     ],
    //   },
    // },
  ];

  return (
    <>
      <SelectTitle>Conditions</SelectTitle>
      {selectedOperations.map((operation) => {
        switch (operation.code) {
          case ConditionCodes.MinimumOrderAmount:
            return (
              <MininumOrderAmountCondition
                key={operation.code}
                code={operation.code}
                configArgs={getConfigArgs(operation)}
                onConfigArgChange={configArgChangeHandler}
                onRemove={removeCard}
              />
            );
          case ConditionCodes.ContainsProducts:
            return (
              <ContainsProductsCondition
                key={operation.code}
                code={operation.code}
                configArgs={getConfigArgs(operation)}
                onConfigArgChange={configArgChangeHandler}
                onRemove={removeCard}
              />
            );
          case ConditionCodes.ContainsMinAnyProducts:
            return (
              <MinimumOrderQuantityCondition
                key={operation.code}
                code={operation.code}
                configArgs={getConfigArgs(operation)}
                onConfigArgChange={configArgChangeHandler}
                onRemove={removeCard}
              />
            );
          case ConditionCodes.CustomerGroup:
            return (
              <CustomerGroupCondition
                key={operation.code}
                code={operation.code}
                configArgs={getConfigArgs(operation)}
                onConfigArgChange={configArgChangeHandler}
                onRemove={removeCard}
              />
            );
          case ConditionCodes.CustomerClubMember:
            return (
              <CustomerClubCondition
                key={operation.code}
                code={operation.code}
                configArgs={getConfigArgs(operation)}
                onConfigArgChange={configArgChangeHandler}
                onRemove={removeCard}
              />
            );
          case ConditionCodes.BuyXGetYFree:
            return (
              <BuyXGetYFreeCondition
                key={operation.code}
                code={operation.code}
                configArgs={getConfigArgs(operation)}
                onConfigArgChange={configArgChangeHandler}
                onRemove={removeCard}
              />
            );
          default:
            return null;
        }
      })}
      <MarginBox mt={15}>
        <Dropdown
          overlay={
            <Menu
              style={{
                padding: "8px 12px",
                border: "1px solid #E5E5E5",
              }}
            >
              {availableOperations
                .filter(
                  (operation) =>
                    !selectedOperations.find(
                      (op) => op.code === operation.operation.code
                    )
                )
                .map((operation) => (
                  <Menu.Item
                    key={operation.operation.code}
                    onClick={() => addOperation(operation.operation)}
                  >
                    {operation.description}
                  </Menu.Item>
                ))}
            </Menu>
          }
          placement="bottomLeft"
        >
          <TwfButton size-twf="md" type-twf="wide">
            Add Condition
          </TwfButton>
        </Dropdown>
      </MarginBox>
    </>
  );
};

export const OperationCard = styled(Card)`
  .ant-card-actions li {
    text-align: left;
  }

  .ant-card-head-title {
    white-space: pre-wrap;
  }
`;

export default Conditions;
